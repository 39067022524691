import React from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import CTAWithFooter from '@/components/cards/CTAWithFooter';
import { useTranslation } from '@separate/hooks/translation';
import { isFeatureEnabled } from '@separate/utilities/features';
import styles from './preferredSignUp.module.scss';

export default function PreferredSignUp() {
  const { t } = useTranslation();
  const router = useRouter();
  const href = '/podercard';
  const eventProperties = {
    href,
    position: 'rewards_dashboard',
    activity: 'select_payout_method',
    step: '1',
  };
  const allowPaidSurveys = isFeatureEnabled('allow_paid_surveys_for_all_users');
  const signUpHeader = allowPaidSurveys ? t('earn.sign_up_with_paid_surveys') : t('earn.sign_up');
  const ctaFooterText = allowPaidSurveys ? t('earn.enroll_with_paid_surveys') : t('earn.enroll');

  const handleClick = () => {
    router?.push(href);
  };

  return (
    <CTAWithFooter
      className={cx(styles.preferred, 'ms-auto', allowPaidSurveys && styles.preferredWithPaidSurveys)}
      bodyClassName="justify-content-center"
      footer={(
        <div className={cx(styles.ctaFooter, 'p-3')}>
          {ctaFooterText}
        </div>
      )}
      href={href}
      onClick={handleClick}
    >
      <div className={styles.content}>
        <p className={styles.heading}>{t('earn.preferred')}</p>
        <span className={styles.body}>{signUpHeader}</span>

        {allowPaidSurveys && (
          <p className='mt-3' dangerouslySetInnerHTML={{ __html: t('earn.sign_up_text')}} />
        )}
      </div>
    </CTAWithFooter>
  );
}
