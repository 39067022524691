import React from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import CTAWithFooter from '@/components/cards/CTAWithFooter';
import { COMPLETE_YOUR_PROFILE_1_ID } from '@/constants/surveys';
import { useTranslation } from '@separate/hooks/translation';
import { useCurrentUser } from '@separate/hooks/user';
import {
  setAnalyticsUser,
} from "@separate/utilities/analytics";
import { isFeatureEnabled } from '@separate/utilities/features';
import styles from './option2SignUp.module.scss';

export default function Option2SignUp({}) {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const router = useRouter();
  const allowPaidSurveys = isFeatureEnabled('allow_paid_surveys_for_all_users');
  const prepaidHeaderText = allowPaidSurveys ? t('earn.prepaid_with_paid_surveys') : t('earn.prepaid');
  const prepaidParagraphText = allowPaidSurveys ? t('earn.prepaid_text_with_paid_surveys') : t('earn.prepaid_text');
  const ctaFooterText = allowPaidSurveys ? t('earn.begin_with_paid_surveys') : t('earn.begin');
  const linkToProfileSurvey = `/surveys/${COMPLETE_YOUR_PROFILE_1_ID}`;
  React.useEffect(() => {
    if (!user) return;

    setAnalyticsUser(user);
  }, [user]);

  function handleClick() {
    router?.push(linkToProfileSurvey);
  }

  return (
    <CTAWithFooter
      href={linkToProfileSurvey}
      className={cx(styles.option2, allowPaidSurveys && styles.option2WithPaidSurveys)}
      footer={(
        <div className={cx(styles.ctaFooter, 'p-3')}>
          {ctaFooterText}
        </div>
      )}
      onClick={handleClick}
    >
      <div className={styles.content}>
        <p className={styles.heading}>{t('earn.alternative')}</p>
        <span className={styles.body}>{prepaidHeaderText}</span>

        {allowPaidSurveys && (
          <p className='mt-3'>{prepaidParagraphText}</p>
        )}
      </div>
    </CTAWithFooter>
  );
}
